 <!---活动-->
<template>
    <div style="height:100%;">
        <a-layout style="height:100%;">
            <a-layout-content style="height:100%;">
                <div class="yj-conten" style="margin-top:12px;min-height: calc(100% - 36px);background-color:#fff;width:calc(100% - 32px);min-width:max-content">
                    <a-layout style="height:100%;background:#fff;">
                        <a-layout-header style="height:auto;background:#fff;padding:0 20px  ;">
                            <a-row>
                                <a-col :span="20">
                                    <a-form layout="horizontal" style="padding:10px  20px 0px;height:auto;">
                                        <a-form-item label="" style="margin-bottom:10px;"
                                                     :label-col="formItemLayout.labelCol"
                                                     :wrapper-col="formItemLayout.wrapperCol">
                                            <a-form layout='inline'>
                                                <a-form-item label="参与类型">
                                                    <a-radio-group :default-value="PartType" v-model="PartType" @change="QueryData">
                                                        <a-radio-button value="-1">全部</a-radio-button>
                                                        <a-radio-button value="0">阅读</a-radio-button>
                                                        <a-radio-button value="1">分享</a-radio-button>
                                                    </a-radio-group>
                                                </a-form-item>
                                            </a-form>
                                        </a-form-item>
                                        <!--<a-form-item label="" style="margin-bottom:10px;"
                                                     :label-col="formItemLayout.labelCol"
                                                     :wrapper-col="formItemLayout.wrapperCol">
                                            <a-form layout='inline'>
                                                <a-form-item label="奖励类型">
                                                    <a-radio-group :default-value="AwardType" v-model="AwardType" @change="QueryData">
                                                        <a-radio-button value="99">全部</a-radio-button>
                                                        <a-radio-button value="0">红包</a-radio-button>
                                                        <a-radio-button value="1">优惠券</a-radio-button>
                                                    </a-radio-group>
                                                </a-form-item>
                                            </a-form>
                                        </a-form-item>-->

                                        <a-form-item label="" style="margin-bottom:0px;"
                                                     :label-col="formItemLayout.labelCol"
                                                     :wrapper-col="formItemLayout.wrapperCol">
                                            <a-form layout='inline'>
                                                <a-form-item label="用户名">
                                                    <a-input placeholder="输入用户名" v-model="UserName" :value="UserName" style="width:278px;" />
                                                </a-form-item>

                                                <a-form-item label="">
                                                    <a-button type="primary" class="margin-r" @click="QueryData">
                                                        查询
                                                    </a-button>

                                                </a-form-item>
                                            </a-form>
                                        </a-form-item>
                                    </a-form>
                                </a-col>
                            </a-row>
                        </a-layout-header>
                        <a-table :columns="columns" rowKey="ID" :data-source="AwardList" :pagination="pagination" @change="pagerChange" style="padding:14px 20px;" class="yj-table-post">
                            <span slot="userinfo" slot-scope="text,record">{{record.UserName}}</span>
                            <span slot="userinfo" slot-scope="text, record"  style="display:flex;flex-direction:row;align-items:center">
                           
                                <img :src="record.UserImg" width="50" height="50" style="float: left;margin-right: 10px;" />
                                 {{record.UserName }}
                            </span>
                            <span slot="PartType" slot-scope="text,record">{{ShowPartType(record.PartType)}}</span>

                            <!--<span slot="AwardType" slot-scope="text,record">
        {{ShowAward(record.AwardType)}}
    </span>-->
                            <span slot-scope="text,record" slot="GetStatus">
                                <span> {{ShowBonusStatus(record.GetStatus)}}</span>
                            </span>
                            <span slot="PayNO" slot-scope="text, record">
                                {{record.PayNO}}
                            </span>
                        </a-table>
                    </a-layout>

                </div>
            </a-layout-content>
        </a-layout>
    </div>
</template>
<script type="text/javascript">
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "Marketing_Activity_Detail",
        data() {
            return {
                columns: [
                    {
                        title: "用户",
                        scopedSlots: { customRender: 'userinfo' },
                        width: 200
                    },
                    {
                        title: "参与类型",
                        scopedSlots: { customRender: 'PartType' },
                        width: 300,

                    },
                    //{
                    //    title: "奖励类型",
                    //    scopedSlots: { customRender: 'AwardType' },
                    //    width: 200,

                    //},
                    {
                        title: "奖励金额",
                        dataIndex: "AwardMoney",
                        width: 300,

                    },
                    {
                        title: "领取状态",
                        scopedSlots: { customRender: 'GetStatus' },
                        width: 300,

                    },
                    {
                        title: "转账单号",
                        scopedSlots: { customRender: 'PayNO' },
                        width: 300,

                    },
                    {
                        title: "领取时间",
                        dataIndex: "InputTime"
                    },
                ],
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 2 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 22 },
                    },
                },
                pagination: {
                    total: 0,
                    defaultPageSize: 10,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                ID: 1,
                UserName:"",
                PartType: "-1",
                AwardList: [],
                PartStatusEnum: [],
                PartTypeEnum: [],
                //AwardEnum: [],
                BonusStatusEnum: []

            };
        },
        methods: {
            //页面跳转事件

            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.loadData();
            },

            //查询
            QueryData() {
                this.loadData();
            },
            //加载活动列表
            loadData() {
                var self = this;
                var op = {
                    url: "/MarketingModule/Activity/GetActivityAwardByCustomID",
                    data: {
                        ActivityID: self.ID,
                        PageIndex: self.pagination.current,
                        PartType: self.PartType,
                        //AwardType:self.AwardType,
                        UserName: self.UserName
                    },
                    OnSuccess: function (res) {
                        res.data.Data.forEach(element => {
                            element.AwardMoney = parseFloat(element.AwardMoney).toFixed(2);
                            element.InputTime =  util.TimeStamptoDateTime(
                                    element.InputTime,
                                    "yyyy-MM-dd hh:mm:ss"
                                );
                        });
                        self.AwardList = res.data.Data;
                        self.pagination.total = res.data.Total;
                        self.pagination.defaultPageSize = res.data.RowCount;
                        self.pagination.current = res.data.PageIndex;

                    }
                };
                http.Post(op);
            },

            //获取参与状态枚举
            getPartStatusEnum: function () {//获取枚举信息
                var Self = this;
                var op = {
                    url: "/Enum",
                    data: {
                        AssemblyName: "Welcome.MarketingModule",//程序集名称
                        TypeName: "Welcome.MarketingModule.Enumerate.EnumActivityPartStatus"//枚举类型名称。要带上命名空间的全称
                    },
                    OnSuccess: function (response) {
                        Self.PartStatusEnum = response.data
                    }
                }
                http.Post(op);
            },
            ShowPartStatus: function (Key) {//枚举从0开始，且连续，可以使用此方法，效率高
                var Self = this;
                if (Self.PartStatusEnum[Key])
                    return Self.PartStatusEnum[Key].Name
                else
                    return "";
            },
            //获取参与类型枚举
            getPartTypeEnum: function () {//获取枚举信息
                var Self = this;
                var op = {
                    url: "/Enum",
                    data: {
                        AssemblyName: "Welcome.MarketingModule",//程序集名称
                        TypeName: "Welcome.MarketingModule.Enumerate.EnumActivityPartType"//枚举类型名称。要带上命名空间的全称
                    },
                    OnSuccess: function (response) {
                        Self.PartTypeEnum = response.data
                    }
                }
                http.Post(op);
            },
            ShowPartType: function (Key) {//枚举从0开始，且连续，可以使用此方法，效率高
                var Self = this;
                if (Self.PartTypeEnum[Key])
                    return Self.PartTypeEnum[Key].Name
                else
                    return "";
            },

            ////获取奖励类型枚举
            //getAwardEnum: function () {//获取枚举信息
            //    var Self = this;
            //    var op = {
            //        url: "/Enum",
            //        data: {
            //            AssemblyName: "Welcome.MarketingModule",//程序集名称
            //            TypeName: "Welcome.MarketingModule.Enumerate.EnumAwardType"//枚举类型名称。要带上命名空间的全称
            //        },
            //        OnSuccess: function (response) {
            //            Self.AwardEnum = response.data
            //        }
            //    }
            //    http.Post(op);
            //},
            //ShowAward: function (Key) {//枚举从0开始，且连续，可以使用此方法，效率高
            //    var Self = this;
            //    if (Self.AwardEnum[Key])
            //        return Self.AwardEnum[Key].Name
            //    else
            //        return "";
            //},
            //领取状态
            getBonusStatusEnum: function () {//获取枚举信息
                var Self = this;
                var op = {
                    url: "/Enum",
                    data: {
                        AssemblyName: "Welcome.MarketingModule",//程序集名称
                        TypeName: "Welcome.MarketingModule.Enumerate.EnumBonusStatus"//枚举类型名称。要带上命名空间的全称
                    },
                    OnSuccess: function (response) {
                        Self.BonusStatusEnum = response.data
                    }
                }
                http.Post(op);
            },
            ShowBonusStatus: function (Key) {//枚举从0开始，且连续，可以使用此方法，效率高
                var Self = this;
                if (Self.BonusStatusEnum[Key])
                    return Self.BonusStatusEnum[Key].Name
                else
                    return "";
            },

            //获取任务完成状态
            getFinishStatusEnum: function () {//获取枚举信息
                var Self = this;
                var op = {
                    url: "/Enum",
                    data: {
                        AssemblyName: "Welcome.MarketingModule",//程序集名称
                        TypeName: "Welcome.MarketingModule.Enumerate.EnumFinishStatus"//枚举类型名称。要带上命名空间的全称
                    },
                    OnSuccess: function (response) {
                        Self.FinishStatusEnum = response.data
                    }
                }
                http.Post(op);
            },
            ShowFinishStatus: function (Key) {//枚举从0开始，且连续，可以使用此方法，效率高
                var Self = this;
                if (Self.FinishStatusEnum[Key])
                    return Self.FinishStatusEnum[Key].Name
                else
                    return "";
            },

        },
        mounted() {
            if (!util.isBlank(this.$route.query.ID)) {
                this.ID = this.$route.query.ID
                this.loadData();
                //this.getAwardEnum();//奖励类型
                this.getPartTypeEnum();//参与类型
                this.getPartStatusEnum();//参与状态
                this.getBonusStatusEnum();//领取状态
            }
        }
    }
</script>
<style type="text/css">
 
 
    .yj-table-post table tr:first-child {
        height: 54px;
    }

    .yj-table-post table tr {
        min-height: 110px;
        height: 110px
    }

        .yj-table-post table tr td:last-child {
            position: relative;
            overflow: hidden;
        }
</style>



